import styled from '@emotion/styled';
import { PlainBtn } from './PlainBtn';

export const SecondaryBtn = styled(PlainBtn)`
    background-color: ${({ theme }) => theme.colors.orange3};
    padding: 16px 24px;
    border-radius: 50px;
    border: 1.5px solid ${({ theme }) => theme.colors.orange2};
    font-weight: 500;
    transition: background-color 0.3s ease-in;

    :hover,
    :focus {
        background-color: ${({ theme }) => theme.colors.orange2};
    }
`;
