import { Article } from '@/Article';
import { CommonHero } from '@/CommonHero';
import { Seo } from '@/layout/Seo';
import styled from '@emotion/styled';
import { StaticImage } from 'gatsby-plugin-image';
import { Fragment } from 'react';

const Patient = styled.div`
    margin-bottom: 40px;

    > h4 {
        display: flex;
        align-items: center;
        color: ${({ theme }) => theme.colors.purple1};
        margin: 0;

        ::after {
            content: '';
            height: 2px;
            width: 100%;
            background-color: ${({ theme }) => theme.colors.orange2};
            flex-shrink: 1000000;
            margin-left: 40px;
        }
    }

    > p {
        margin: 12px 0 0;
    }

    > div {
        display: grid;
        grid-template-columns: repeat(auto-fill, min(100%, 344px));
        gap: 16px;
        margin-top: 40px;

        > .gatsby-image-wrapper {
            border-radius: 10px;
            opacity: 0.9999; //safari overflow bug fix
        }
    }

    @media (min-width: 1280px) {
        margin-bottom: 80px;
    }

    :last-of-type {
        margin-bottom: 0;
    }
`;

export const PatientInfo = ({ bio }: { bio?: boolean }) => (
    <Fragment>
        <Patient>
            <h4>Raelynn Fitzsimmons</h4>
            {bio ? (
                <p>
                    I have been a patient of Dr. Nuttall’s for 25 years now. The staff is always so
                    friendly and helpful. I decided to do a smile makeover because I felt it was
                    necessary because my front teeth were very damaged. My two very front teeth are
                    implants and no one would ever know because the implant crowns look so good. I
                    also have two crowns on the two other teeth on each side of the implant crowns
                    and I couldn’t be happier with my results; I now have full chewing function and
                    a beautiful smile that I am proud of. Dr. Nuttall has been our family dentist
                    for years and I am happy to say he has a patient for life. He is always so fun,
                    I recommend him to everyone.
                </p>
            ) : (
                ''
            )}
            <div>
                <StaticImage
                    src="../../../assets/images/for-patients/patient-journeys/raelynn-1.jpg"
                    alt="Raelynn Fitzsimmons"
                    width={344}
                    height={200}
                />
                <StaticImage
                    src="../../../assets/images/for-patients/patient-journeys/raelynn-2.jpg"
                    alt="Raelynn Fitzsimmons"
                    width={344}
                    height={200}
                />
                <StaticImage
                    src="../../../assets/images/for-patients/patient-journeys/raelynn-3.jpg"
                    alt="Raelynn Fitzsimmons"
                    width={344}
                    height={200}
                />
                <StaticImage
                    src="../../../assets/images/for-patients/patient-journeys/raelynn-4.jpg"
                    alt="Raelynn Fitzsimmons"
                    width={344}
                    height={200}
                />
            </div>
        </Patient>
        <Patient>
            <h4>Pamela Hill</h4>
            {bio ? (
                <p>
                    I have been coming to Dr. Nuttall’s office for 12 years now. I love the friendly
                    staff and the ease of getting appointments at convenient times. I always can
                    count on competent care with Dr. Nuttall. His is always so upbeat and welcoming,
                    it makes you feel like family. The reason I did a smile makeover was because my
                    front teeth were showing their age, they had wear and cracks, and I knew it was
                    time to do something about it. Dr. Nuttall showed me my options and after much
                    discussion I decided to go with six crowns in the front. I am extremely happy
                    with the results. I smile way more often and don’t mind pictures of me now. The
                    whole process was seamless, I appreciated that I was able to go to the lab and
                    work with technician to pick out a custom shade to get my new teeth just right
                    for me. Dr. Nuttall and his assistants even stayed after hours to make sure my
                    teeth were perfect. I love my new smile!
                </p>
            ) : (
                ''
            )}
            <div>
                <StaticImage
                    src="../../../assets/images/for-patients/patient-journeys/pamela-1.jpg"
                    alt="Pamela Hill"
                    width={344}
                    height={200}
                />
                <StaticImage
                    src="../../../assets/images/for-patients/patient-journeys/pamela-2.jpg"
                    alt="Pamela Hill"
                    width={344}
                    height={200}
                />
                <StaticImage
                    src="../../../assets/images/for-patients/patient-journeys/pamela-3.jpg"
                    alt="Pamela Hill"
                    width={344}
                    height={200}
                />
                <StaticImage
                    src="../../../assets/images/for-patients/patient-journeys/pamela-4.jpg"
                    alt="Pamela Hill"
                    width={344}
                    height={200}
                />
            </div>
        </Patient>
        <Patient>
            <h4>Kirk Aardahl</h4>
            {bio ? (
                <p>
                    I love being a patient at Dr. Nuttall’s because he always makes each visit
                    comfortable, its also a plus that he has friendly staff and music to choose from
                    at each appointment. I decided to do a six crown smile makeover because I was
                    unhappy with my smile and it was time to gain my confidence back. Dr. Nuttall
                    talked me through each visit and he even kept up communication with me outside
                    of the office. I am very pleased with the outcome of my smile and can even smile
                    with my teeth for photos now. Dr. Nuttall has my business for life.
                </p>
            ) : (
                ''
            )}
            <div>
                <StaticImage
                    src="../../../assets/images/for-patients/patient-journeys/kirk-1.jpg"
                    alt="Kirk Aardahl"
                    width={344}
                    height={200}
                />
                <StaticImage
                    src="../../../assets/images/for-patients/patient-journeys/kirk-2.jpg"
                    alt="Kirk Aardahl"
                    width={344}
                    height={200}
                />
                <StaticImage
                    src="../../../assets/images/for-patients/patient-journeys/kirk-3.jpg"
                    alt="Kirk Aardahl"
                    width={344}
                    height={200}
                />
                <StaticImage
                    src="../../../assets/images/for-patients/patient-journeys/kirk-4.jpg"
                    alt="Kirk Aardahl"
                    width={344}
                    height={200}
                />
            </div>
        </Patient>
        <Patient>
            <h4>David Knisley</h4>
            {bio ? (
                <p>
                    I love being a patient at Dr. Nuttall’s because of the friendly family
                    atmosphere, they always make you feel comfortable during each visit. I decided
                    to do a smile makeover because I had problems with my front tooth and ended up
                    loosing it completely. I was devastated to have a visible missing tooth right in
                    the front for everyone to see when I smiled. Dr. Nuttall suggested a four unit
                    bridge to replace the missing tooth and to give life back to my smile. Once the
                    permanent bridge was put in my mouth I felt an immediate difference. I got my
                    confidence back to be able to smile with pride and it fit absolutely perfect.
                    Dr. Nuttall does a great job at making you feel like family in his office and
                    making sure each visit is pain free. I love the fact that you can pick music or
                    catch up on your favorite movie or TV show, it really helps to put your mind at
                    ease. His office staff is fantastic and Dr. Nuttall does magic working on teeth,
                    he is a special person and I can’t recommend him enough.
                </p>
            ) : (
                ''
            )}
            <div>
                <StaticImage
                    src="../../../assets/images/for-patients/patient-journeys/david-1.jpg"
                    alt="David Knisley"
                    width={344}
                    height={200}
                />
                <StaticImage
                    src="../../../assets/images/for-patients/patient-journeys/david-2.jpg"
                    alt="David Knisley"
                    width={344}
                    height={200}
                />
                <StaticImage
                    src="../../../assets/images/for-patients/patient-journeys/david-3.jpg"
                    alt="David Knisley"
                    width={344}
                    height={200}
                />
                <StaticImage
                    src="../../../assets/images/for-patients/patient-journeys/david-4.jpg"
                    alt="David Knisley"
                    width={344}
                    height={200}
                />
            </div>
        </Patient>
        <Patient>
            <h4>Christina Parks</h4>
            {bio ? (
                <p>
                    I started coming to Dr. Nuttall’s in 2013, and I am so glad I did! Dr. Nuttall
                    and his entire staff are very compassionate and truly care about their patients.
                    They have always made me feel at ease and comfortable. I was very embarrassed
                    about the condition of my teeth when I started coming here and I know I needed
                    major dental work done. In the past, I rarely smiled for pictures and if I did
                    it was never with my teeth showing. I was also always nervous about meeting new
                    people because of my teeth. Now I look forward to going to the dentist and I
                    have the confidence to smile in every picture. I only wish I would have done
                    this sooner. Dr. Nuttall’s office is always so positive and upbeat, and they
                    make you feel like a friend, rather than a patient. I highly recommend Dr.
                    Nuttall and have referred many people to his office.
                </p>
            ) : (
                ''
            )}
            <div>
                <StaticImage
                    src="../../../assets/images/for-patients/patient-journeys/christina-1.jpg"
                    alt="Christina Parks"
                    width={344}
                    height={200}
                />
                <StaticImage
                    src="../../../assets/images/for-patients/patient-journeys/christina-2.jpg"
                    alt="Christina Parks"
                    width={344}
                    height={200}
                />
                <StaticImage
                    src="../../../assets/images/for-patients/patient-journeys/christina-3.jpg"
                    alt="Christina Parks"
                    width={344}
                    height={200}
                />
                <StaticImage
                    src="../../../assets/images/for-patients/patient-journeys/christina-4.jpg"
                    alt="Christina Parks"
                    width={344}
                    height={200}
                />
            </div>
        </Patient>
    </Fragment>
);

const PatientJourneys: Page = () => (
    <Fragment>
        <Seo
            title="Patient Journeys | Top Auburn Denture Services"
            description="Explore patient journeys at Nuttall Smiles, the embodiment of our commitment as the best dentist in Auburn and surrounding areas. Our team is ready to help you."
        />
        <CommonHero img>
            <StaticImage
                src="../../../assets/images/for-patients/patient-journeys/hero.jpg"
                alt="girl smile"
                layout="fullWidth"
                loading="eager"
            />
        </CommonHero>
        <Article big>
            <PatientInfo bio />
        </Article>
    </Fragment>
);

export default PatientJourneys;
