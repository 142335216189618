import { theme as siteTheme } from '@/layout/styles/GlobalStyles';
import styled from '@emotion/styled';
import Select, { components, DropdownIndicatorProps, StylesConfig, Theme } from 'react-select';
import arrow from '../../assets/images/icons/down-arrow.svg';
import { caption } from './layout/styles/Typography';

export const Input = styled.input<{ rows?: number }>`
    ${caption};
    background-color: transparent;
    border: 1px solid ${({ theme }) => theme.colors.gray3};
    border-radius: 8px;
    color: ${({ theme }) => theme.colors.gray5};
    width: 100%;
    padding: 13.5px 16px;
    display: block;
    outline: none;
    transition: border-color 0.2s ease-in;
    margin-bottom: 16px;

    :hover,
    :focus {
        border-color: ${({ theme }) => theme.colors.purple1};
    }

    ::placeholder {
        color: ${({ theme }) => theme.colors.gray3};
    }

    :focus:invalid {
        border-color: #e75842;
    }

    @media (min-width: 1024px) {
        padding: 9.5px 16px;
    }

    @media (min-width: 1600px) {
        padding: 10px 16px;
    }
`;

export const Label = styled.label`
    color: ${({ theme }) => theme.colors.gray4};
    margin-bottom: 8px;
    display: block;
    text-transform: capitalize;
`;

export const Thanks = styled.div<{ submit: boolean; lightBg?: boolean }>`
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    display: flex;
    padding: 32px;
    border-radius: 10px;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    text-align: center;
    z-index: ${({ submit }) => (submit ? 10 : -1)};
    opacity: ${({ submit }) => (submit ? 1 : 0)};
    transform: ${({ submit }) => (submit ? 'scale(1)' : 'scale(0)')};
    transition: all 0.3s ease-in;
    transition-property: opacity, transform;
    color: ${({ theme }) => theme.colors.gray5};
    background-color: ${({ theme }) => theme.colors.white};

    > h1 {
        margin-top: 0;
        color: ${({ theme }) => theme.colors.purple1};
    }

    > p {
        margin-bottom: 0;
        color: ${({ theme }) => theme.colors.gray5};
        font-size: 1.5rem;
    }

    @media (min-width: 768px) {
        padding: 64px;
        border-radius: 15px;
    }
`;

const DropdownIndicator = (props: DropdownIndicatorProps) => {
    return (
        <components.DropdownIndicator {...props}>
            <img src={arrow} alt="dropdown" width={14} height={9} />
        </components.DropdownIndicator>
    );
};

const DropdownTheme = (theme: Theme) => ({
    ...theme,
    borderRadius: 8,
    background: siteTheme.colors.white,
    boxShadow: 'none',
    colors: {
        ...theme.colors,
        primary: siteTheme.colors.purple1,
        primary25: siteTheme.colors.purple3,
        primary50: siteTheme.colors.purple2,
    },
});

const customStyles: StylesConfig = {
    container: provided => ({
        ...provided,
        width: '100%',
        maxWidth: '96px',
    }),
    control: provided => ({
        ...provided,
        padding: '13.5px 16px',
        border: `1px solid ${siteTheme.colors.gray3}`,
        marginBottom: '16px',
        transition: 'border 0.2s ease-in',
        ':hover': {
            borderColor: siteTheme.colors.purple1,
        },
        '@media only screen and (min-width: 1024px)': {
            padding: '9.5px 8px',
        },
        '@media only screen and (min-width: 1600px)': {
            padding: '10px 8px',
        },
    }),
    dropdownIndicator: provided => ({
        ...provided,
        padding: '0 !important',
    }),
    indicatorSeparator: () => ({
        display: 'none',
    }),
    valueContainer: provided => ({
        ...provided,
        padding: 0,
        fontSize: '0.75rem',
        lineHeight: '175.18%',
        '@media only screen and (min-width: 1600px)': {
            fontSize: '0.875rem',
        },
    }),
    input: provided => ({
        ...provided,
        padding: '0 !important',
        margin: '0 !important',
    }),
    singleValue: provided => ({
        ...provided,
        color: siteTheme.colors.gray5,
    }),
    placeholder: provided => ({
        ...provided,
        color: siteTheme.colors.gray3,
        margin: 0,
    }),
    menu: provided => ({
        ...provided,
        marginTop: '-8px',
    }),
    menuList: provided => ({
        ...provided,
        border: `1px solid ${siteTheme.colors.purple1}`,
        borderRadius: 8,
        fontSize: '0.75rem',
        lineHeight: '175.18%',
        '@media (pointer: fine)': {
            '::-webkit-scrollbar': {
                height: '6px',
                width: '6px',
            },
            '::-webkit-scrollbar-track': {
                borderRadius: '30px',
                background: 'rgb(178, 153, 103, 0.15)',
            },
            '::-webkit-scrollbar-thumb': {
                backgroundColor: '#8525ff',
                borderRadius: '30px',
            },
            scrollbarWidth: 'thin',
            scrollbarColor: '#cbb9ff #8525ff',
        },
        '@media only screen and (min-width: 1600px)': {
            fontSize: '0.875rem',
        },
    }),
};

type CustomSelectProps = {
    options: object[];
    id: string;
    name: string;
    'aria-labelledby': string;
    placeholder: string;
};

export const CustomSelect = (props: CustomSelectProps) => {
    return (
        <Select
            {...props}
            theme={DropdownTheme}
            components={{ DropdownIndicator }}
            styles={customStyles}
        />
    );
};
