import styled from '@emotion/styled';
import useLocationData from '@s/hooks/useLocationData';
import pattern from '../../assets/images/background-pattern.svg';
import { Breadcrumb } from './Breadcrumb';

const StyledHero = styled.section`
    > .gatsby-image-wrapper {
        min-height: 168px;
        max-height: 300px;
        z-index: 1;
    }

    @media (min-width: 1024px) {
        > .gatsby-image-wrapper {
            max-height: 432px;
        }
    }
`;

const Box = styled.div<{ img?: boolean; charCount: number }>`
    background-color: ${({ theme }) => theme.colors.white};
    background-image: url(${pattern});
    background-position: top 0 left -75px;
    padding: 26px 16px 45px;
    box-shadow: 0px 94px 94px rgba(86, 67, 109, 0.07),
        0px 17.1431px 50.2924px rgba(86, 67, 109, 0.0395717),
        0px 7.16151px 34.7509px rgba(86, 67, 109, 0.030253),
        0px 4.08184px 24.6026px rgba(86, 67, 109, 0.0244546),
        0px 2.24208px 15.2336px rgba(86, 67, 109, 0.0179373);
    border-radius: 15px;
    z-index: 3;
    position: relative;
    overflow-x: hidden;

    > h1 {
        font-size: 1.5rem;
        line-height: 175.18%;
        text-align: center;
        color: ${({ theme }) => theme.colors.purple2};
        margin: 28.53px 0 0;
        text-transform: capitalize;
    }

    @media (min-width: 1024px) {
        max-width: 1064px;
        margin: ${({ img }) => (img ? '0 auto -93px' : '40px auto 0')};
        transform: ${({ img }) => (img ? 'translateY(-50%)' : 'none')};
        padding: 24px 40px 42px;
        margin-bottom: ${({ charCount, img }) => (img ? (charCount > 60 ? '-110px' : '-93px') : 0)};

        > p {
            margin-left: auto;
        }

        > h1 {
            margin-top: 16px;
            font-size: ${({ charCount }) => (charCount > 36 ? '2rem' : '2.5rem')};
        }
    }
`;

type CommonHeroProps = {
    children?: React.ReactNode;
    img?: boolean;
    blogTitle?: string;
};

export const CommonHero = ({ children, img, blogTitle }: CommonHeroProps) => {
    const { category, subCategory, title } = useLocationData();
    const theTitle = blogTitle ? blogTitle : title;
    const theCategory = subCategory ? subCategory : category;

    return (
        <StyledHero>
            {children}
            <Box img={img} charCount={theTitle ? theTitle.length : theCategory.length}>
                <Breadcrumb category={category} subCategory={subCategory} title={theTitle} />
                <h1>{theTitle ? theTitle : theCategory}</h1>
            </Box>
        </StyledHero>
    );
};
