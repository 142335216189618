import { css } from '@emotion/react';

export const h1 = css`
    font-size: 2.75rem;
    line-height: 132.68%;
    font-weight: 700;
    text-transform: capitalize;

    @media (min-width: 1024px) {
        font-size: 3rem;
    }
`;

export const h2 = css`
    font-size: 2rem;
    font-weight: 600;
    line-height: 175.18%;
    text-transform: capitalize;

    @media (min-width: 1024px) {
        font-size: 2.25rem;
    }

    @media (min-width: 1366px) {
        font-size: 2.5rem;
    }
`;

export const h3 = css`
    font-size: 1.5rem;
    font-weight: 600;
    line-height: 175.18%;
    text-transform: capitalize;

    @media (min-width: 1024px) {
        font-size: 1.75rem;
    }

    @media (min-width: 1366px) {
        font-size: 2rem;
    }
`;

export const h4 = css`
    font-size: 1.125rem;
    line-height: 145.68%;
    font-weight: 600;
    text-transform: capitalize;

    @media (min-width: 1366px) {
        font-size: 1.5rem;
        line-height: 175.18%;
    }
`;

export const textSm = css`
    font-size: 0.875rem;
    line-height: 175.18%;

    @media (min-width: 1600px) {
        font-size: 1rem;
        line-height: 175.18%;
    }
`;

export const caption = css`
    font-size: 0.75rem;
    line-height: 175.18%;

    @media (min-width: 1600px) {
        font-size: 0.875rem;
        line-height: 175.18%;
    }
`;
