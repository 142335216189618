import { flexCenter } from '@/layout/styles/classes';
import styled from '@emotion/styled';
import { PlainBtn } from './PlainBtn';

export const IconBtn = styled(PlainBtn)<{ sm?: boolean }>`
    ${flexCenter};
    border-radius: 50%;
    width: ${({ sm }) => (sm ? '30px' : '56px')};
    height: ${({ sm }) => (sm ? '30px' : '56px')};
`;
