import { AppointmentForm } from '@/AppointmentForm';
import CLink from '@/CLink';
import { info } from '@/layout/NavLinks';
import { Map } from '@/Map';
import { SectionTag } from '@/SectionTag';
import styled from '@emotion/styled';
import bg from '../../../assets/images/background-pattern.svg';
import phone from '../../../assets/images/layout/phone.svg';
import pin from '../../../assets/images/layout/pin.svg';
import speak from '../../../assets/images/layout/speak.svg';
import { flexCenter, flexSpace, flexStart, px } from './styles/classes';

const StyledContact = styled.section`
    ${px};
    padding-top: 64px;
    padding-bottom: 48px;
    background: url(${bg}) no-repeat;

    > div {
        margin-bottom: 48px;

        > h3 {
            margin: 0;
        }
    }

    @media (min-width: 1150px) {
        ${flexCenter};
        padding-top: 64px;
        padding-bottom: 96px;
        background-size: cover;

        > div {
            flex-grow: 1;
            max-width: 536px;
            margin-right: ${({ theme }) => theme.spacing.xl};
            margin-bottom: 0;
        }

        > form {
            max-width: 434px;
        }
    }
`;

const Flex = styled.div`
    margin: 24px 0;

    @media (min-width: 768px) {
        ${flexSpace};
        align-items: flex-start;
        max-width: ${({ theme }) => theme.sizes.lg};
    }

    @media (min-width: 1150px) {
        margin: 40px 0 48px;
    }
`;

const Info = styled.div`
    margin-bottom: 24px;

    > a,
    > p {
        ${flexStart};
        margin: 0 0 24px;

        > img {
            margin-right: 28px;
        }

        > span {
            max-width: 148px;
        }

        &.contact-section-speak {
            margin-bottom: 0;

            > img {
                margin-right: 16px;
            }

            > span {
                color: ${({ theme }) => theme.colors.purple2};
            }
        }
    }

    > a {
        transition: color 0.3s ease-in-out;

        :hover,
        :focus {
            color: ${({ theme }) => theme.colors.purple1};
        }
    }

    @media (min-width: 768px) {
        margin-bottom: 0;
    }
`;

const Hours = styled.div`
    max-width: ${({ theme }) => theme.sizes.md};

    > p {
        ${flexSpace};
        margin: 0 0 16px;

        > span:first-of-type {
            font-weight: 600;
        }

        :last-of-type {
            margin-bottom: 0;
            color: ${({ theme }) => theme.colors.gray3};
        }
    }

    @media (min-width: 768px) {
        margin-bottom: 0;
        width: 240px;
    }
`;

export const ContactSection = () => {
    return (
        <StyledContact>
            <div>
                <SectionTag title="Reach out to Us" bc="#ECDAE8" tc="#B97AAB" />
                <h3>Contact / Hours</h3>
                <Flex>
                    <Info>
                        <CLink to={info.address.link}>
                            <img src={pin} alt="" />{' '}
                            <span>{info.address.text}</span>
                        </CLink>
                        <CLink to={info.phone.link}>
                            <img src={phone} alt="" /> <span>{info.phone.text}</span>
                        </CLink>
                        <p className="contact-section-speak">
                            <img src={speak} alt="" /> <span>We speak Spanish</span>
                        </p>
                    </Info>

                    <Hours>
                        <p>
                            <span>Monday</span>
                            <span>8 am - 5 pm</span>
                        </p>
                        <p>
                            <span>Tuesday</span>
                            <span>8 am - 5 pm</span>
                        </p>
                        <p>
                            <span>Wednesday</span>
                            <span>8 am - 5 pm</span>
                        </p>
                        <p>
                            <span>Thursday</span>
                            <span>7 am - 5 pm</span>
                        </p>
                        <p>
                            <span>Friday</span>
                            <span>Closed</span>
                        </p>
                    </Hours>
                </Flex>

                <Map />
            </div>
            <AppointmentForm />
        </StyledContact>
    );
};
