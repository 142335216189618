export const info = {
    phone: {
        link: 'tel:+12535445501',
        text: '(253) 544-5501',
    },
    address: {
        text: '722 12th Street SE Auburn, WA 98002',
        link: 'https://g.page/NuttallSmiles?share',
    },
    social: {
        facebook: 'https://www.facebook.com/kentdnuttall',
        twitter: 'https://twitter.com/DrKentNuttall',
        google: 'https://g.page/NuttallSmiles?share',
        youtube: 'https://www.youtube.com/@NuttallSmilesTV',
        yelp: 'https://www.yelp.com/biz/nuttall-smiles-auburn',
        instagram: 'https://www.instagram.com/nuttallsmiles',
    },
};

export type NavLinksTypes = { text: string; link: string }[];

export const navLinks: NavLinksTypes = [
    {
        text: 'about',
        link: 'submenu',
    },
    {
        text: 'for patients',
        link: 'submenu',
    },
    {
        text: 'technology',
        link: 'submenu',
    },
    {
        text: 'general & preventive dentistry',
        link: 'submenu',
    },
    {
        text: 'cosmetic dentistry',
        link: 'submenu',
    },
];

type SubNavLinksTypes = {
    [index: string]: NavLinksTypes;
};

export const subNavLinks: SubNavLinksTypes = {
    about: [
        {
            text: 'Meet Dr. Kent Nuttall',
            link: '/about/meet-dr-kent-nuttall/',
        },
        {
            text: 'Meet Our Team',
            link: '/about/meet-our-team/',
        },
        {
            text: 'Our Safety',
            link: '/about/our-safety/',
        },
        {
            text: 'Blog',
            link: '/about/blog/',
        },
        {
            text: 'See All',
            link: '/about/',
        },
    ],
    'for patients': [
        {
            text: 'Payment Options',
            link: '/for-patients/payment-options/',
        },
        {
            text: 'Online Patient Form',
            link: '/for-patients/online-patient-form/',
        },
        {
            text: 'Our Offers',
            link: '/for-patients/our-offers/',
        },
        {
            text: 'Patient Journeys',
            link: '/for-patients/patient-journeys/',
        },
    ],
    technology: [
        {
            text: '3D Imaging',
            link: '/technology/3d-imaging/',
        },
        {
            text: 'BOTOX®',
            link: '/technology/botox/',
        },
        {
            text: 'Cavitron',
            link: '/technology/cavitron/',
        },
        {
            text: 'Chairside Computers',
            link: '/technology/chairside-computers/',
        },
        {
            text: 'Diagnostic Wax-Up Tooth Models',
            link: '/technology/diagnostic-wax-up-tooth-models/',
        },
        {
            text: 'Digital X-Rays',
            link: '/technology/digital-x-rays/',
        },
        {
            text: 'Intraoral Camera',
            link: '/technology/intraoral-camera/',
        },
        {
            text: 'OraVerse®',
            link: '/technology/oraverse/',
        },
        {
            text: 'Panorex Imaging',
            link: '/technology/panorex-imaging/',
        },
        {
            text: 'Pearl - Dental AI Technology',
            link: '/technology/pearl-dental-ai-technology/',
        },
        {
            text: 'Single Tooth Anesthesia',
            link: '/technology/single-tooth-anesthesia/',
        },
        {
            text: 'Waterlase® Dental Laser',
            link: '/technology/waterlase-dental-laser/',
        },
        {
            text: 'WaveOne Endodontic Files',
            link: '/technology/waveone-endodontic-files/',
        },
        {
            text: 'See All',
            link: '/technology/',
        },
    ],
    'general & preventive dentistry': [
        {
            text: 'Dental Sealants',
            link: '/general-and-preventive-dentistry/dental-sealants/',
        },
        {
            text: 'Dental Filling',
            link: '/general-and-preventive-dentistry/dental-filling/',
        },
        {
            text: 'Fluoride Treatments',
            link: '/general-and-preventive-dentistry/fluoride-treatments/',
        },
        {
            text: 'Dental Night Guard',
            link: '/general-and-preventive-dentistry/dental-night-guard/',
        },
        {
            text: 'Root Canal',
            link: '/general-and-preventive-dentistry/root-canal/',
        },
        {
            text: 'Sports Mouth Guard',
            link: '/general-and-preventive-dentistry/sports-mouth-guard/',
        },
        {
            text: 'TMJ Treatment',
            link: '/general-and-preventive-dentistry/tmj-treatment/',
        },
        {
            text: 'Tooth Extraction',
            link: '/general-and-preventive-dentistry/tooth-extraction/',
        },
        {
            text: 'Wisdom Teeth Removal',
            link: '/general-and-preventive-dentistry/wisdom-teeth-removal/',
        },
        {
            text: 'Sedation Dentistry',
            link: '/general-and-preventive-dentistry/sedation-dentistry/',
        },
        {
            text: 'Frenectomy',
            link: '/general-and-preventive-dentistry/frenectomy/',
        },
        {
            text: 'Periodontal Disease Treatment',
            link: '/general-and-preventive-dentistry/periodontal-disease-treatment/',
        },
        {
            text: 'See All',
            link: '/general-and-preventive-dentistry/',
        },
    ],
    'cosmetic dentistry': [
        {
            text: 'Dental Bonding',
            link: '/cosmetic-dentistry/dental-bonding/',
        },
        {
            text: 'Dental Bridge',
            link: '/cosmetic-dentistry/dental-bridge/',
        },
        {
            text: 'Dental Crowns',
            link: '/cosmetic-dentistry/dental-crowns/',
        },
        {
            text: 'Dental Veneers',
            link: '/cosmetic-dentistry/dental-veneers/',
        },
        {
            text: 'Partial Dentures',
            link: '/cosmetic-dentistry/partial-dentures/',
        },
        {
            text: 'Snap On Smile',
            link: '/cosmetic-dentistry/snap-on-smile/',
        },
        {
            text: 'Teeth Whitening',
            link: '/cosmetic-dentistry/teeth-whitening/',
        },
        {
            text: 'Dental Implants',
            link: '/cosmetic-dentistry/dental-implants/',
        },
        {
            text: 'See All',
            link: '/cosmetic-dentistry/',
        },
    ],
};

export const footerNavLinks: NavLinksTypes = [
    {
        text: 'About',
        link: getSubNavLink('about'),
    },
    {
        text: 'Dental Technology',
        link: getSubNavLink('technology'),
    },
    {
        text: 'Contact Us',
        link: '#contact-us',
    },
    {
        text: 'Blog',
        link: getSubNavLink('blog'),
    },
    {
        text: 'Privacy Policy',
        link: '/privacy-policy/',
    },
];

export const footerServiceLinks: NavLinksTypes = [
    {
        text: 'General & Preventive Dentistry Auburn, WA',
        link: getSubNavLink('general & preventive dentistry'),
    },
    {
        text: 'Cosmetic Dentistry Auburn, WA',
        link: getSubNavLink('cosmetic dentistry'),
    },
    {
        text: 'Sedation Dentistry',
        link: getSubNavLink('Sedation Dentistry'),
    },
    {
        text: 'Dental Implants Auburn, WA',
        link: getSubNavLink('dental implants'),
    },
    {
        text: 'Dental Crowns Auburn, WA',
        link: getSubNavLink('dental crowns'),
    },
    {
        text: 'Tooth Extractions Auburn, WA',
        link: getSubNavLink('tooth extraction'),
    },
];

export function getSubNavLink(text: string) {
    let filteredLink: { link: string; text: string }[] = [];

    const isCategory = navLinks.filter(link => link.text === text).length > 0;

    if (isCategory) {
        return subNavLinks[text][subNavLinks[text].length - 1].link;
    }

    for (const key of Object.keys(subNavLinks)) {
        filteredLink = subNavLinks[key].filter(
            link => link.text.toLowerCase() === text.toLowerCase()
        );
        if (filteredLink.length > 0) break;
    }

    if (filteredLink.length === 0) return '';
    else return filteredLink[0].link;
}
