import { css } from '@emotion/react';
import styled from '@emotion/styled';
import { Menu, MenuButton, MenuItems, MenuLink, MenuPopover } from '@reach/menu-button';
import '@reach/menu-button/styles.css';
import { Link } from 'gatsby';
import { Fragment, useEffect, useState } from 'react';
import AnimateHeight from 'react-animate-height';
import { StyledMenuButton } from './Nav';
import { NavLinksTypes, subNavLinks } from './NavLinks';

const StyledMenuPopover = styled(MenuPopover)`
    &[data-reach-menu-popover] {
        z-index: 100;
        box-shadow: 0px 21px 38px rgba(87, 99, 145, 0.16);
        border-radius: 15px;
        border: 1px solid #c8d0d2;

        &[hidden] {
            display: block;

            &.mob-nav-dropdown-not-expanded {
                display: none;
            }
        }

        &:not([hidden]) {
            &.mob-nav-dropdown-not-expanded {
                display: block;
            }
        }
    }

    [data-reach-menu-items] {
        background: ${({ theme }) => theme.colors.white};
        padding: 8px 0px;
        width: 284px;
        border: none;
        border-radius: 15px;
        max-height: calc(100vh - 80px);
        overflow-y: auto;
    }

    [data-reach-menu-item] {
        padding: 12px 24px;
        font-size: 0.875rem;
        font-weight: 400;
        line-height: 175.18%;
        text-transform: capitalize;
        color: ${({ theme }) => theme.colors.gray4};
        animation-timing-function: ease-in-out;
        background-color: transparent;
        transition: all 0.2s ease-in-out;
        transition-property: color, background-color, font-weight;

        &[see-all='true'] {
            text-decoration: underline;
            color: ${({ theme }) => theme.colors.purple1};

            &[data-selected] {
                color: ${({ theme }) => theme.colors.purple1};
            }
        }

        &.nav-link-current-page {
            color: ${({ theme }) => theme.colors.orange1};
            font-weight: 600;
        }

        &[data-selected] {
            color: ${({ theme }) => theme.colors.gray5};
            background-color: ${({ theme }) => theme.colors.orange3};
            font-weight: 700;
        }
    }

    &[data-reach-menu-popover].mob-nav-dropdown-expanded:not([hidden]) {
        [data-reach-menu-item] {
            animation-name: mob-nav-dropdown-items-show;
            animation-duration: 0.3s;
            animation-fill-mode: backwards;
        }
    }

    &[data-reach-menu-popover][hidden].mob-nav-dropdown-expanded {
        [data-reach-menu-item] {
            animation-fill-mode: forwards;
            animation-duration: 0.15s;
            animation-name: mob-nav-dropdown-items-hide;
        }
    }

    @keyframes mob-nav-dropdown-items-show {
        0% {
            opacity: 0;
        }

        100% {
            opacity: 1;
        }
    }

    @keyframes mob-nav-dropdown-items-hide {
        0% {
            opacity: 1;
        }

        100% {
            opacity: 0;
        }
    }
`;

type StyledPopoverProps = {
    isExpanded: boolean;
    links: NavLinksTypes;
    navId: number;
};

const StyledPopover = ({ isExpanded, links, navId }: StyledPopoverProps) => {
    const [expanded, setExpanded] = useState(isExpanded);

    useEffect(() => {
        let timeoutId: NodeJS.Timeout;

        if (isExpanded) {
            timeoutId = setTimeout(() => setExpanded(true), 10);
        } else if (!isExpanded) {
            timeoutId = setTimeout(() => setExpanded(false), 700);
        }
        return () => {
            clearTimeout(timeoutId);
        };
    }, [isExpanded, links.length]);

    const animationDelay = links.length > 8 ? '0.05s' : '0.1s';

    return (
        <StyledMenuPopover
            className={expanded ? 'mob-nav-dropdown-expanded' : 'mob-nav-dropdown-not-expanded'}
        >
            <AnimateHeight
                duration={400}
                delay={isExpanded ? 0 : links.length > 8 ? 400 : 300}
                easing="ease-in-out"
                height={isExpanded ? 'auto' : 0}
            >
                <MenuItems
                    tabIndex={0}
                    as="nav"
                    aria-labelledby={`menu-button--desktop-menu--${navId}`}
                >
                    {links.map((link, i, arr) => (
                        <MenuLink
                            as={Link}
                            key={i}
                            to={link.link}
                            activeClassName="nav-link-current-page"
                            see-all={link.text === 'See All' ? 'true' : 'false'}
                            css={css`
                                animation-delay: ${isExpanded
                                    ? `calc(${animationDelay} * ${i + 1})`
                                    : `calc(${animationDelay} * ${arr.length - i})`};
                            `}
                        >
                            {link.text}
                        </MenuLink>
                    ))}
                </MenuItems>
            </AnimateHeight>
        </StyledMenuPopover>
    );
};

type DesktopSubNavProps = {
    text: string;
    category: string;
    navId: number;
};

export const DesktopSubNav = ({ text, category, navId }: DesktopSubNavProps) => {
    return (
        <Menu>
            {({ isExpanded }) => (
                <Fragment>
                    <StyledMenuButton
                        as={MenuButton}
                        className={category === text ? 'nav-menu-button-active' : ''}
                        id={`menu-button--desktop-menu--${navId}`}
                    >
                        {text}
                    </StyledMenuButton>

                    <StyledPopover
                        isExpanded={isExpanded}
                        links={subNavLinks[text]}
                        navId={navId}
                    />
                </Fragment>
            )}
        </Menu>
    );
};
