import { PrimaryBtn } from '@/Buttons/PrimaryBtn';
import CLink from '@/CLink';
import styled from '@emotion/styled';
import dots from '../../../assets/images/icons/dotted-line.svg';
import facebook from '../../../assets/images/icons/facebook.svg';
import google from '../../../assets/images/icons/google.svg';
import instagram from '../../../assets/images/icons/instagram.svg';
import phone from '../../../assets/images/icons/phone.svg';
import twitter from '../../../assets/images/icons/twitter.svg';
import yelp from '../../../assets/images/icons/yelp.svg';
import youtube from '../../../assets/images/icons/youtube.svg';
import { footerNavLinks, footerServiceLinks, info } from './NavLinks';
import { textSm } from './styles/Typography';
import { flexCenter, flexSpace, nmx, px } from './styles/classes';

const StyledFooter = styled.section`
    ${px};
    border-top: 14px solid ${({ theme }) => theme.colors.orange2};
    background-color: ${({ theme }) => theme.colors.purple5};
    padding-top: 64px;
    padding-bottom: 32px;
    color: ${({ theme }) => theme.colors.white};

    @media (min-width: 1024px) {
        > div {
            ${flexSpace};
            align-items: stretch;
        }
    }

    @media (min-width: 1366px) {
        > div {
            width: 75vw;
            margin: 0 auto;
            max-width: 1400px;
        }
    }
`;

const Intro = styled.div`
    max-width: 400px;
    margin-bottom: 40px;

    > h1 {
        font-size: 1.75rem;
        line-height: 175.18%;
        font-weight: 700;
        text-transform: uppercase;
        margin: 0 0 12px;
    }

    > p {
        ${textSm};
        color: ${({ theme }) => theme.colors.gray3};
        max-width: 280px;
        margin: 0 0 16px;
    }

    > a {
        width: fit-content;
    }

    @media (min-width: 1024px) {
        display: flex;
        justify-content: flex-start;
        flex-direction: column;
        margin-right: 2.2vw; //32px at 1440
        margin-bottom: 0;
    }
`;

const Social = styled.div`
    ${flexSpace};
    max-width: 400px;
    margin-top: 40px;

    > p {
        margin: 0 -12px 0 0;
    }

    > a {
        > img {
            filter: invert(1);
        }

        :hover,
        :focus {
            > img {
                filter: invert(75%) sepia(42%) saturate(1266%) hue-rotate(196deg) brightness(105%)
                    contrast(103%);
            }
        }
    }

    @media (min-width: 1024px) {
        margin-top: auto;
        margin-bottom: 0;
        width: 400px;
    }
`;

const FooterLinks = styled.div`
    display: flex;
    flex-direction: column;
    margin-bottom: 40px;

    > p {
        font-weight: 600;
        margin: 0 0 16px;
        text-transform: uppercase;
    }

    > a {
        ${textSm};
        margin-bottom: 12px;
        color: ${({ theme }) => theme.colors.white};
        transition: color 0.3s ease-in-out;
        width: fit-content;

        &:focus,
        &:hover {
            color: ${({ theme }) => theme.colors.purple3};
        }

        :last-of-type {
            margin-bottom: 0;
        }
    }

    @media (min-width: 1024px) {
        margin-bottom: 0;
    }
`;

const Copyright = styled.p`
    ${flexCenter};
    ${nmx};
    margin-top: 64px;
    margin-bottom: 0;

    > span {
        background-color: ${({ theme }) => theme.colors.purple5};
        color: ${({ theme }) => theme.colors.gray3};
        flex-grow: 0;
        flex-shrink: 0;
        margin-left: 16px;
    }

    ::before,
    ::after {
        content: '';
        height: 16px;
        flex-grow: 1;
        background: url(${dots}) repeat-x;
    }

    ::before {
        margin-left: -16px;
    }

    @media (min-width: 1024px) {
        grid-column: 1 / -1;

        ::before {
            margin-left: 0;
        }
    }
`;

export const Footer = () => {
    return (
        <StyledFooter>
            <div>
                <Intro>
                    <h1>nuttall smiles</h1>
                    <p>We care for people, not just teeth. Let us be your dentist for life.</p>
                    <PrimaryBtn icon="true" as={CLink} to={info.phone.link}>
                        <img src={phone} alt="" />
                        Call Us at {info.phone.text}
                    </PrimaryBtn>
                    <Social>
                        <p>Follow us at:</p>
                        
                        <CLink to={info.social.instagram}>
                            <img src={instagram} alt="nuttall smiles instagram page" />
                        </CLink>
                        <CLink to={info.social.google}>
                            <img src={google} alt="nuttall smiles google page" />
                        </CLink>
                        <CLink to={info.social.facebook}>
                            <img src={facebook} alt="nuttall smiles facebook page" />
                        </CLink>
                        <CLink to={info.social.twitter}>
                            <img src={twitter} alt="nuttall smiles twitter page" />
                        </CLink>
                        <CLink to={info.social.youtube}>
                            <img src={youtube} alt="nuttall smiles youtube page" />
                        </CLink>
                        <CLink to={info.social.yelp}>
                            <img src={yelp} alt="nuttall smiles yelp page" />
                        </CLink>
                    </Social>
                </Intro>
                <FooterLinks>
                    <p>navigate</p>
                    {footerNavLinks.map((link, i) => (
                        <CLink to={link.link} key={i}>
                            {link.text}
                        </CLink>
                    ))}
                </FooterLinks>
                <FooterLinks>
                    <p>services</p>
                    {footerServiceLinks.map((link, i) => (
                        <CLink to={link.link} key={i}>
                            {link.text}
                        </CLink>
                    ))}
                </FooterLinks>
            </div>
            <Copyright>
                <span>Nuttall Smiles Copyright © {new Date().getFullYear()}</span>
            </Copyright>
        </StyledFooter>
    );
};
