import { ContactSection } from '@/layout/ContactSection';
import { Footer } from '@/layout/Footer';
import { Nav } from '@/layout/Nav';
import { GlobalStyles, theme } from '@/layout/styles/GlobalStyles';
import { ThemeProvider } from '@emotion/react';
import { SkipNavContent } from '@reach/skip-nav';

export const DefaultLayout: React.FC = ({ children }) => {
    return (
        <ThemeProvider theme={theme}>
            <GlobalStyles />
            <Nav />
            <SkipNavContent />
            <main>{children}</main>
            <ContactSection />
            <Footer />
        </ThemeProvider>
    );
};
