import { css } from '@emotion/react';
import styled from '@emotion/styled';
import { Fragment } from 'react';
import arrow from '../../assets/images/icons/micro-arrow.svg';
import CLink from './CLink';
import { getSubNavLink } from './layout/NavLinks';

const StyledBreadcrumb = styled.p<{ subCategory: string }>`
    margin: 0;
    display: flex;
    width: fit-content;

    > a,
    > span {
        padding: 4px 12px;
        border-radius: 20px;
        font-size: 0.75rem;
        line-height: 175.18%;
        text-transform: capitalize;
        max-width: 15ch;
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
    }

    > a {
        transition: box-shadow 0.2s ease-in-out;

        :hover,
        :focus {
            box-shadow: 0 3px 15px rgba(0, 0, 0, 0.15);
        }
    }

    > img {
        margin: 0 10px;
    }

    @media (max-width: 400px) {
        ${({ subCategory }) =>
            subCategory &&
            css`
                > span:last-of-type {
                    max-width: 10ch;
                }
            `};
    }

    @media (min-width: 600px) {
        > a,
        > span {
            max-width: 33ch;
        }
    }

    @media (min-width: 1600px) {
        > span,
        > a {
            font-size: 0.875rem;
            padding: 6px 14px;
        }

        > img {
            margin: 0 12px;
        }
    }
`;

type BreadcumbProps = {
    category: string;
    subCategory: string;
    title: string;
};

export const Breadcrumb = ({ category, subCategory, title }: BreadcumbProps) => {
    const homeBc = '#E8E3F7';
    const homeC = '#9888C7';

    let categoryBc = '';
    let categoryC = '';

    const subCategoryBc = '#DAE3F3';
    const subCategoryC = '#8691af';

    let titleBc = '';
    let titleC = '';

    if (category === 'general & preventive dentistry') {
        categoryBc = '#F3E7D0';
        categoryC = '#B88942';
        titleBc = '#D5F2F0';
        titleC = '#65A5A1';
    } else if (category === 'cosmetic dentistry') {
        categoryBc = '#D0F3DA';
        categoryC = '#7CA768';
        titleBc = '#DAE3F3';
        titleC = '#8691AF';
    } else if (category === 'oral dentistry') {
        categoryBc = '#F1F1DB';
        categoryC = '#A8A96F';
        titleBc = '#DBF2D5';
        titleC = '#83AC69';
    } else if (category === 'about') {
        categoryBc = '#FCDEED';
        categoryC = '#B07F97';
        titleBc = '#D5EBF2';
        titleC = '#7196B0';
    } else if (category === 'for patients') {
        categoryBc = '#DAF5F4';
        categoryC = '#629A93';
        titleBc = '#F2E7F6';
        titleC = '#BD88AE';
    } else if (category === 'technology') {
        categoryBc = '#F3E1E1';
        categoryC = '#AF7474';
        titleBc = '#F2EFD5';
        titleC = '#979561';
    } else if (subCategory) {
        titleBc = '#f2e7f6';
        titleC = '#bd88ae';
    } else {
        categoryBc = '#DAE3F3';
    }

    const categoryArray = getSubNavLink(category).split('/');

    const noCategoryPage =
        categoryArray[categoryArray.length - 2] !== category.replace(/ /g, '-') ||
        categoryArray.length === 1;

    return (
        <StyledBreadcrumb subCategory={subCategory}>
            <CLink to="/" style={{ backgroundColor: homeBc, color: homeC }}>
                Home
            </CLink>
            {noCategoryPage ? (
                <Fragment>
                    <img src={arrow} alt="" />
                    <span style={{ backgroundColor: categoryBc, color: categoryC }}>
                        {category}
                    </span>
                </Fragment>
            ) : (
                <Fragment>
                    <img src={arrow} alt="" />
                    <CLink
                        to={getSubNavLink(category)}
                        style={{ backgroundColor: categoryBc, color: categoryC }}
                    >
                        {category}
                    </CLink>
                </Fragment>
            )}
            {subCategory ? (
                title ? (
                    <Fragment>
                        <img src={arrow} alt="" />
                        <CLink
                            to={getSubNavLink(subCategory)}
                            style={{ backgroundColor: subCategoryBc, color: subCategoryC }}
                        >
                            {subCategory}
                        </CLink>
                    </Fragment>
                ) : (
                    <Fragment>
                        <img src={arrow} alt="" />
                        <span style={{ backgroundColor: subCategoryBc, color: subCategoryC }}>
                            {subCategory}
                        </span>
                    </Fragment>
                )
            ) : (
                ''
            )}
            {title && (
                <Fragment>
                    <img src={arrow} alt="" />
                    <span style={{ backgroundColor: titleBc, color: titleC }}>{title}</span>
                </Fragment>
            )}
        </StyledBreadcrumb>
    );
};
