import styled from '@emotion/styled';

const StyledTag = styled.p`
    margin: 0;
    padding: 4px 12px;
    border-radius: 20px;
    font-size: 0.75rem;
    line-height: 175.18%;
    text-transform: capitalize;
    width: fit-content;

    @media (min-width: 1600px) {
        font-size: 0.875rem;
        padding: 6px 14px;
    }
`;

type TagProps = {
    title: string;
    bc: string;
    tc: string;
};

export const SectionTag = ({ title, bc, tc }: TagProps) => {
    return <StyledTag style={{ backgroundColor: bc, color: tc }}>{title}</StyledTag>;
};
