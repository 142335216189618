import { DefaultLayout } from '@s/layouts/DefaultLayout';
import { SiteContextProvider } from '@s/layouts/SiteContextProvider';
import { cloneElement } from 'react';

// eslint-disable-next-line
export const wrapRootElement = ({ element }) => {
    return <SiteContextProvider>{element}</SiteContextProvider>;
};

// eslint-disable-next-line
export const wrapPageElement = ({ element, props }) => {
    if (element.type.Layout === false) {
        return cloneElement(element, props);
    }
    const Layout = element.type.Layout ?? DefaultLayout;
    return <Layout {...props}>{element}</Layout>;
};

export const onInitialClientRender = () => {
    window.$crisp = [];
    window.CRISP_WEBSITE_ID = 'c73302ce-de9f-403c-82ba-7f5238eb12d7';
    const script = document.createElement('script');
    script.src = 'https://client.crisp.chat/l.js';
    script.async = 1;
    document.getElementsByTagName('head')[0].appendChild(script);
};
