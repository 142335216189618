import styled from '@emotion/styled';
import smArrow from '../../../assets/images/icons/sm-arrow.svg';
import { PlainBtn } from './PlainBtn';

export const PrimaryBtn = styled(PlainBtn)<{ icon?: string }>`
    border-radius: 50px;
    background-color: ${({ theme, icon }) =>
        icon === 'true' ? theme.colors.purple1 : theme.colors.white};
    padding: 16px 24px;
    color: ${({ theme, icon }) => (icon === 'true' ? theme.colors.white : theme.colors.purple1)};
    font-weight: 500;
    border: 1.5px solid ${({ theme }) => theme.colors.purple1};
    transition: transform 0.3s ease-in-out;
    position: relative;
    display: inline-flex;
    align-items: center;

    ::after {
        content: url(${smArrow});
        margin-left: 20px;
        height: 16px;
        width: 16px;
        display: ${({ icon }) => (icon === 'true' ? 'none' : 'block')};
        margin-top: -2px;
    }

    > img:first-of-type {
        filter: ${({ icon }) => (icon === 'true' ? 'brightness(0) invert(1)' : 'none')};
        margin-right: 20px;
        display: ${({ icon }) => (icon === 'true' ? 'block' : 'none')};
    }

    :hover,
    :focus {
        ::after {
            animation: translateBtnArrow 0.9s ease-out infinite;
        }

        > img:first-of-type {
            animation: rotateBtnIcon 0.9s ease-out infinite;
        }
    }

    @keyframes translateBtnArrow {
        33% {
            transform: translateX(-4px);
        }

        66% {
            transform: translateX(8px);
        }

        100% {
            transform: translateX(0);
        }
    }

    @keyframes rotateBtnIcon {
        33% {
            transform: rotate(-20.84deg);
        }

        66% {
            transform: rotate(30deg);
        }

        100% {
            transform: rotate(0);
        }
    }
`;
