import { PlainBtn } from '@/Buttons/PlainBtn';
import styled from '@emotion/styled';
import { Menu, MenuButton, MenuItem, MenuItems, MenuLink, MenuPopover } from '@reach/menu-button';
import '@reach/menu-button/styles.css';
import { Link } from 'gatsby';
import { Fragment, useEffect, useRef, useState } from 'react';
import back from '../../../assets/images/icons/back.svg';
import close from '../../../assets/images/icons/close.svg';
import { StyledMenuButton } from './Nav';
import { NavLinksTypes, subNavLinks } from './NavLinks';
import { flexSpace, flexStart } from './styles/classes';

const StyledMenuPopover = styled(MenuPopover)`
    &[data-reach-menu-popover] {
        ${flexSpace};
        flex-direction: column;
        position: fixed;
        z-index: 100;
        top: 0;
        right: 0;
        transform: translateX(120vw);
        transition: transform 0.3s ease-in-out;
        border-radius: 20px 0px 0px 20px;

        &[hidden] {
            display: flex;

            &.mob-nav-dropdown-not-expanded {
                display: none;
            }
        }

        &:not([hidden]) {
            &.mob-nav-dropdown-not-expanded {
                display: flex;
            }
        }

        [data-reach-menu-items] {
            background: ${({ theme }) => theme.colors.purple5};
            padding: 96px 16px 100px;
            width: 100vw;
            max-width: 420px;
            height: calc(100vh - 64px);
            max-height: 1000px;
            display: grid;
            grid-template-columns: 1fr;
            grid-template-rows: auto auto 1fr;
            border-radius: 20px 0px 0px 20px;

            //fixing default styles
            border: none;
            font-size: 1rem;
            white-space: normal;

            > nav {
                ${flexStart};
                flex-direction: column;
                overflow-y: auto;
                height: 100%;
            }
        }

        [data-reach-menu-item] {
            color: ${({ theme }) => theme.colors.white};
            text-transform: capitalize;
            padding: 0;
            background: none;
            margin-bottom: 32px;
            transition: color 0.3s ease-in-out;

            :last-of-type {
                margin-bottom: 0;
            }

            &[see-all='true'] {
                text-decoration: underline;
            }

            &:hover {
                color: ${({ theme }) => theme.colors.purple3};
            }

            &.nav-link-current-page {
                color: ${({ theme }) => theme.colors.orange2};
            }

            &[data-selected] {
                background: none;
                color: ${({ theme }) => theme.colors.purple3};
            }
        }

        &.mob-nav-dropdown-expanded:not([hidden]) {
            transform: translateX(0);
        }

        @media (max-height: 800px) {
            [data-reach-menu-items] {
                padding-top: 64px;
                padding-bottom: 64px;
            }

            [data-reach-menu-item] {
                margin-bottom: 24px;

                :last-of-type {
                    margin-bottom: 0;
                }
            }
        }
    }
`;

const Buttons = styled.div`
    ${flexSpace};
    margin-bottom: 32px;

    > button {
        margin: 0 !important;

        &[data-selected],
        :hover,
        :focus {
            opacity: 0.5;
        }
    }

    & + h3 {
        margin: 0 0 48px;
        text-align: center;
        color: ${({ theme }) => theme.colors.white};
    }

    @media (max-height: 800px) {
        margin-bottom: 24px;

        & + h3 {
            margin: 0 0 36px;
        }
    }
`;

type PopoverProps = {
    isExpanded: boolean;
    links: NavLinksTypes;
    closeNav: () => void;
    closeSubNav: () => void;
    text: string;
    navId: number;
};

const Popover = ({ isExpanded, links, closeNav, closeSubNav, text, navId }: PopoverProps) => {
    const [expanded, setExpanded] = useState(isExpanded);

    useEffect(() => {
        let timeoutId: NodeJS.Timeout;

        if (isExpanded) {
            timeoutId = setTimeout(() => setExpanded(true), 10);
        } else if (!isExpanded) {
            timeoutId = setTimeout(() => setExpanded(false), 300);
        }
        return () => {
            clearTimeout(timeoutId);
        };
    }, [isExpanded]);

    return (
        <StyledMenuPopover
            portal={false}
            className={expanded ? 'mob-nav-dropdown-expanded' : 'mob-nav-dropdown-not-expanded'}
        >
            <MenuItems as="nav" aria-labelledby={`menu-button--mob-menu--${navId}`}>
                <Buttons>
                    <MenuItem as={PlainBtn} onSelect={closeSubNav}>
                        <img src={back} alt="close nav menu" width={34} height={29} />
                    </MenuItem>
                    <MenuItem as={PlainBtn} onSelect={closeNav}>
                        <img src={close} alt="close nav menu" width={24} height={17} />
                    </MenuItem>
                </Buttons>
                <h3>{text}</h3>
                <nav>
                    {links.map((link, i) => (
                        <MenuLink
                            key={i}
                            as={Link}
                            to={link.link}
                            activeClassName="nav-link-current-page"
                            see-all={link.text === 'See All' ? 'true' : 'false'}
                        >
                            {link.text}
                        </MenuLink>
                    ))}
                </nav>
            </MenuItems>
        </StyledMenuPopover>
    );
};

type MobSubNavProps = {
    text: string;
    closeNav: () => void;
    category: string;
    navId: number;
};

export const MobSubNav = ({ text, closeNav, category, navId }: MobSubNavProps) => {
    const MenuBtnRef = useRef<HTMLButtonElement>(null);

    const closeSubNav = () => {
        if (MenuBtnRef.current)
            MenuBtnRef.current.dispatchEvent(new Event('mousedown', { bubbles: true }));
    };

    return (
        <Menu>
            {({ isExpanded }) => (
                <Fragment>
                    <StyledMenuButton
                        as={MenuButton}
                        ref={MenuBtnRef}
                        className={category === text ? 'nav-menu-button-active' : ''}
                        id={`menu-button--mob-menu--${navId}`}
                    >
                        {text}
                    </StyledMenuButton>
                    <Popover
                        isExpanded={isExpanded}
                        links={subNavLinks[text]}
                        closeNav={closeNav}
                        closeSubNav={closeSubNav}
                        text={text}
                        navId={navId}
                    />
                </Fragment>
            )}
        </Menu>
    );
};
