exports.components = {
  "component---src-pages-404-tsx": () => import("./../../../src/pages/404.tsx" /* webpackChunkName: "component---src-pages-404-tsx" */),
  "component---src-pages-about-index-tsx": () => import("./../../../src/pages/about/index.tsx" /* webpackChunkName: "component---src-pages-about-index-tsx" */),
  "component---src-pages-about-meet-dr-kent-nuttall-tsx": () => import("./../../../src/pages/about/meet-dr-kent-nuttall.tsx" /* webpackChunkName: "component---src-pages-about-meet-dr-kent-nuttall-tsx" */),
  "component---src-pages-about-meet-our-team-tsx": () => import("./../../../src/pages/about/meet-our-team.tsx" /* webpackChunkName: "component---src-pages-about-meet-our-team-tsx" */),
  "component---src-pages-about-our-safety-tsx": () => import("./../../../src/pages/about/our-safety.tsx" /* webpackChunkName: "component---src-pages-about-our-safety-tsx" */),
  "component---src-pages-for-patients-online-patient-form-tsx": () => import("./../../../src/pages/for-patients/online-patient-form.tsx" /* webpackChunkName: "component---src-pages-for-patients-online-patient-form-tsx" */),
  "component---src-pages-for-patients-our-offers-tsx": () => import("./../../../src/pages/for-patients/our-offers.tsx" /* webpackChunkName: "component---src-pages-for-patients-our-offers-tsx" */),
  "component---src-pages-for-patients-patient-journeys-tsx": () => import("./../../../src/pages/for-patients/patient-journeys.tsx" /* webpackChunkName: "component---src-pages-for-patients-patient-journeys-tsx" */),
  "component---src-pages-for-patients-payment-options-tsx": () => import("./../../../src/pages/for-patients/payment-options.tsx" /* webpackChunkName: "component---src-pages-for-patients-payment-options-tsx" */),
  "component---src-pages-index-tsx": () => import("./../../../src/pages/index.tsx" /* webpackChunkName: "component---src-pages-index-tsx" */),
  "component---src-pages-privacy-policy-tsx": () => import("./../../../src/pages/privacy-policy.tsx" /* webpackChunkName: "component---src-pages-privacy-policy-tsx" */),
  "component---src-templates-blog-page-tsx": () => import("./../../../src/templates/blog-page.tsx" /* webpackChunkName: "component---src-templates-blog-page-tsx" */),
  "component---src-templates-blog-tsx": () => import("./../../../src/templates/blog.tsx" /* webpackChunkName: "component---src-templates-blog-tsx" */),
  "component---src-templates-service-tsx": () => import("./../../../src/templates/service.tsx" /* webpackChunkName: "component---src-templates-service-tsx" */)
}

