import styled from '@emotion/styled';
import arrow from '../../assets/images/icons/sm-arrow.svg';
import { IconBtn } from './Buttons/IconBtn';
import CLink from './CLink';
import { info } from './layout/NavLinks';

const MapContainer = styled.div`
    position: relative;
    aspect-ratio: 342 / 220;
    border-radius: 15px;
    max-width: ${({ theme }) => theme.sizes.md};
    scroll-margin-top: 24px;
    overflow: hidden;
    opacity: 0.9999;

    > iframe {
        border: 0;
        width: 100%;
        height: 100%;
        margin-bottom: -7px;
    }

    @media (min-width: 1150px) {
        max-width: 556px;
        height: 380px;
        aspect-ratio: unset;
    }
`;

const Btn = styled(CLink)`
    position: absolute;
    right: 8.5px;
    top: 8px;
    width: 30px !important;
    height: 30px !important;
    background-color: ${({ theme }) => theme.colors.purple1} !important;
    z-index: 10;
    transition: background-color 0.3s ease-in-out;

    > img {
        filter: brightness(0) invert(1);
    }

    :hover,
    :focus {
        background-color: ${({ theme }) => theme.colors.purple2} !important;
    }

    @media (min-width: 1024px) {
        width: 48px !important;
        height: 48px !important;
        right: 16px;
        bottom: 16px;

        > img {
            transform: scale(1.3);
        }
    }
`;

export const Map = () => {
    return (
        <MapContainer id="map">
            <iframe
                title="nuttall smiles location on map"
                src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d2705.8910647071652!2d-122.22111408438015!3d47.29692837916532!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x5490f7d902f1a0c1%3A0x2e5fdc30a60c0dc9!2sNuttall%20Smiles!5e0!3m2!1sen!2s!4v1645974645403!5m2!1sen!2s"
                allowFullScreen
                loading="lazy"
            ></iframe>
            <IconBtn as={Btn} to={info.address.link} aria-label="open map in new window">
                <img src={arrow} alt="" width={16} height={16} />
            </IconBtn>
        </MapContainer>
    );
};
