// eslint-disable-next-line
import { useLocation } from '@reach/router';

export default function useLocationData() {
    const location = useLocation();
    const url = location.pathname.slice(1, -1).split('/');

    let category = url[0].replaceAll('-', ' ');

    if (category === 'general and preventive dentistry')
        category = 'general & preventive dentistry';

    let subCategory = '';
    let title = '';

    if (url.length > 1) {
        if (url[1] === 'blog') {
            subCategory = url[1];
            if (url[2] !== 'page') title = url[2];
        } else title = url[1];
    }

    if (title) title = title.replaceAll('-', ' ');
    if (title === 'meet dr kent nuttall') title = 'dr kent nuttall, DMD';
    if (title === 'tmj treatment') title = 'TMJ treatment';
    if (title === '3d imaging') title = '3D imaging';
    if (title === 'botox') title = 'BOTOX®';
    if (title === 'oraverse') title = 'oraverse®';
    if (title === 'waterlase dental laser') title = 'Waterlase® Dental Laser';
    if (title === 'waveone endodontic files') title = 'WaveOne endodontic files';
    if (title === 'pearl dental ai technology') title = 'pearl dental aI technology';

    return { category, subCategory, title };
}
