import { css } from '@emotion/react';

export const px = css`
    padding-left: 16px;
    padding-right: 16px;

    @media (min-width: 768px) {
        padding-left: 40px;
        padding-right: 40px;
    }

    @media (min-width: 1366px) {
        padding-left: 64px;
        padding-right: 64px;
    }

    @media (min-width: 1440px) {
        padding-left: 80px;
        padding-right: 80px;
    }
`;

//negative margin to negate above padding
export const nmx = css`
    margin-left: -16px;
    margin-right: -16px;

    @media (min-width: 768px) {
        margin-left: -40px;
        margin-right: -40px;
    }

    @media (min-width: 1366px) {
        margin-left: -64px;
        margin-right: -64px;
    }

    @media (min-width: 1440px) {
        margin-left: -80px;
        margin-right: -80px;
    }
`;

export const flexCenter = css`
    display: flex;
    align-items: center;
    justify-content: center;
`;

export const flexSpace = css`
    display: flex;
    align-items: center;
    justify-content: space-between;
`;

export const flexStart = css`
    display: flex;
    align-items: center;
    justify-content: flex-start;
`;
