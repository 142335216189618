import styled from '@emotion/styled';
import orangeSq from '../../assets/images/orange-squares.svg';
import purpleSq from '../../assets/images/purple-squares.svg';
import { px } from './layout/styles/classes';

export const Article = styled.article<{ big?: boolean }>`
    ${px};
    padding-top: 40px;
    padding-bottom: 64px;
    max-width: ${({ big }) => (big ? '1078px' : '878px')};
    margin: 0 auto;

    a:not([data-type='button']) {
        color: ${({ theme }) => theme.colors.purple1};
        transition: color 0.3s ease-in-out;
        font-weight: 600;

        :hover,
        :focus {
            color: ${({ theme }) => theme.colors.purple2};
        }
    }

    > p:first-of-type {
        margin-top: 0;
    }

    > a:last-of-type,
    > p:last-of-type {
        margin-bottom: 0;
    }

    > .gatsby-resp-image-wrapper {
        margin: 24px auto;
        max-width: 520px !important;
        border-radius: 15px;

        .gatsby-resp-image-background-image {
            padding-bottom: 60% !important;
            border-radius: 15px;
        }

        .gatsby-resp-image-image {
            border-radius: 15px;
            object-fit: cover;
        }
    }

    @media (min-width: 768px) {
        max-width: ${({ big }) => (big ? '1144px' : '926px')};
    }

    @media (min-width: 1024px) {
        padding-top: 80px;
        padding-bottom: 112px;

        > .gatsby-resp-image-wrapper {
            margin: 64px auto;
            position: relative;
            max-width: 591px !important;

            ::before,
            ::after {
                content: '';
                position: absolute;
            }

            ::before {
                width: 107px;
                height: 61px;
                background: url(${orangeSq}) no-repeat;
                bottom: 0;
                left: -123px;
            }

            ::after {
                width: 117px;
                height: 74px;
                background: url(${purpleSq}) no-repeat;
                top: 0;
                right: -133px;
            }

            .gatsby-resp-image-background-image {
                padding-bottom: 46.4% !important;
            }
        }
    }

    @media (min-width: 1366px) {
        padding-left: 0;
        padding-right: 0;
        max-width: ${({ big }) => (big ? '1064px' : '846px')};
    }
`;
